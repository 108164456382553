// @flow strict

import React, { type Node } from 'react';
// $FlowFixMe[untyped-import]
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid';

type Props = {
  +message: ?FbtWithoutString | ?string,
  +testid?: string,
  +onDismiss: () => void,
};

export default function ErrorMessage({ message, testid, onDismiss }: Props): Node {
  if (message == null || message === '') {
    return null;
  }

  return (
    <div sxt="rounded-md bg-green-50 p-4" data-testid={testid}>
      <div sxt="flex">
        <div sxt="flex-shrink-0">
          <CheckCircleIcon sxt="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div sxt="ml-3">
          <h3 sxt="text-sm leading-5 font-medium text-green-800">{message}</h3>
        </div>
        <div sxt="ml-auto pl-3">
          <div sxt="-mx-1\.5 -my-1\.5">
            <button
              type="button"
              sxt="inline-flex bg-green-50 rounded-md p-1\.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              onClick={onDismiss}
            >
              <span sxt="sr-only">Dismiss</span>
              <XIcon sxt="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
