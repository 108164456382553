// @flow

import { useSetRecoilState } from 'recoil';

import { appMessageAtom } from './recoil/appMessageAtom';

type UseAppMessage = {
  +showError: (FbtWithoutString | string) => void,
  +showSuccess: (FbtWithoutString | string) => void,
  +clear: () => void,
};

export function useAppMessage(): UseAppMessage {
  const setAppMessage = useSetRecoilState(appMessageAtom);

  return {
    showError: (message) => {
      setAppMessage({ message, type: 'error' });
    },
    showSuccess: (message) => {
      setAppMessage({ message, type: 'success' });
    },
    clear: () => {
      setAppMessage({ message: null, type: null });
    },
  };
}
