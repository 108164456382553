// @flow strict-local

import { useState, useContext, type Node } from 'react';
import { fbt } from 'fbt';
import { useRouter } from 'next/router';
import Link from 'next/link';

import Logout from './Logout';
import { useAppMessage } from '../../useAppMessage';
import { CurrentUserContext } from '../../CurrentUserContext';

export default function Profile(): Node {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { id: userId } = useContext(CurrentUserContext);
  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };
  const router = useRouter();
  const { clear } = useAppMessage();

  if (userId == null) {
    return null;
  }

  const onLogoutSuccess = () => {
    setIsMenuVisible(false);
    clear();
    router.push('/login');
  };

  return (
    <div sxt="ml-3 relative">
      <button
        type="button"
        sxt="max-w-xs border-2 bg-gray-200 border-gray-200 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
        id="user-menu-button"
        aria-expanded="false"
        aria-haspopup="true"
        onClick={toggleMenu}
      >
        <span sxt="sr-only">
          <fbt desc="Call to action on small screen">Open profile menu</fbt>
        </span>
        <span sxt="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
          <svg sxt="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      </button>

      <Menu
        isVisible={isMenuVisible}
        onLogoutSuccess={onLogoutSuccess}
        setIsMenuVisible={setIsMenuVisible}
      />
    </div>
  );
}

type MenuProps = {
  +isVisible: boolean,
  +onLogoutSuccess: () => void,
  +setIsMenuVisible: (isVisible: boolean) => void,
};

function Menu({ isVisible, onLogoutSuccess, setIsMenuVisible }: MenuProps): Node {
  if (!isVisible) {
    return null;
  }

  return (
    <div
      sxt="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow border-2 border-gray-100 py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu-button"
      tabIndex="-1"
    >
      <Link href="/settings">
        <a
          href="/settings"
          sxt={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100`}
          role="menuitem"
          tabIndex="-1"
          id="user-menu-item-1"
          onClick={() => setIsMenuVisible(false)}
        >
          <fbt desc="Item in Profile popup menu">Settings</fbt>
        </a>
      </Link>

      <Logout onSuccess={onLogoutSuccess}>
        {({ isLoading, handleLogout }) => (
          <a
            href="/logout"
            sxt={`block px-4 py-2 text-sm ${
              isLoading ? 'text-gray-300' : 'text-gray-700'
            } hover:bg-gray-100`}
            role="menuitem"
            tabIndex="-1"
            id="user-menu-item-2"
            onClick={handleLogout}
          >
            <fbt desc="Item in Profile popup menu">Log out</fbt>
          </a>
        )}
      </Logout>
    </div>
  );
}
