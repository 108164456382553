// @flow strict-local

import { useState, type Node } from 'react';
import Link from 'next/link';

import { MenuWeb, MobileMenu, MobileMenuButton } from './Menu';
import AppMessage from '../AppMessage';
import Profile from './Profile';

type Props = {
  +children?: Node,
  +header: FbtWithoutString,
};

export default function MainLayout({ children, header }: Props): Node {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  // https://tailwindui.com/components/application-ui/application-shells/stacked#component-10058606cac5398d7fa2c73b64089874
  return (
    <div>
      <nav sxt="bg-white shadow">
        <div sxt="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div sxt="flex items-center justify-between h-16">
            <div sxt="flex items-center">
              <div sxt="flex-shrink-0">
                <Link href="/">
                  <a href="/" aria-label="Fitlr">
                    <img sxt="h-10 w-10" src="/logo.svg" alt="Fitlr logo" />
                  </a>
                </Link>
              </div>
              <div sxt="hidden md:block">
                <MenuWeb />
              </div>
            </div>
            <div sxt="hidden md:block">
              <div sxt="ml-4 flex items-center md:ml-6">
                <Profile />
              </div>
            </div>
            <div sxt="-mr-2 flex md:hidden">
              <MobileMenuButton isOpen={isMobileMenuOpen} onClick={toggleMobileMenu} />
            </div>
          </div>
        </div>

        <div sxt={`${isMobileMenuOpen ? 'block md:hidden' : 'hidden md:hidden'}`}>
          <MobileMenu />
        </div>
      </nav>

      <header>
        <div sxt="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 sxt="text-3xl font-bold leading-tight text-gray-900" data-testid="header">
            {header}
          </h1>
        </div>
      </header>

      <main>
        <div sxt="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <AppMessage />
          <div>{children}</div>
        </div>
      </main>
    </div>
  );
}
