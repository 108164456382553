// @flow strict-local

import { useContext, type Node } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
// $FlowFixMe[untyped-import]
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { fbt } from 'fbt';

import Logout from './Logout';
import { CurrentUserContext } from '../../CurrentUserContext';

const getMenuItems = () => [
  { title: fbt('Workouts', 'Main app menu item'), href: '/workouts' },
  { title: fbt('Exercises', 'Main app menu item'), href: '/exercises' },
  { title: fbt('Dashboard', 'Main app menu item'), href: '/dashboard' },
];

export const MenuWeb = (): Node => {
  const menuItems = getMenuItems();
  const router = useRouter();
  const { id: userId } = useContext(CurrentUserContext);

  if (userId == null) {
    return null;
  }

  return (
    <div sxt="ml-10 flex items-baseline">
      {menuItems.map((item, index) => (
        <Link href={item.href} key={`menuItem-${index}`}>
          <a
            href={item.href}
            sxt={`${
              router.pathname.startsWith(item.href)
                ? 'bg-gray-200 text-gray-900'
                : 'text-gray-900 hover:bg-gray-100 hover:text-gray-900'
            } rounded-md ml-4 py-2 px-3 inline-flex items-center text-sm font-medium`}
          >
            {item.title}
          </a>
        </Link>
      ))}
    </div>
  );
};

export const MobileMenu = (): Node => {
  const menuItems = getMenuItems();
  const router = useRouter();
  const { id: userId } = useContext(CurrentUserContext);

  if (userId == null) {
    return null;
  }

  return (
    <>
      <div sxt="px-2 pt-2 pb-3 sm:px-3">
        {menuItems.map((item, index) => (
          <Link href={item.href} key={`menuItem-${index}`}>
            <a
              href={item.href}
              sxt={
                router.pathname.startsWith(item.href)
                  ? 'block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700'
                  : 'mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700'
              }
            >
              {item.title}
            </a>
          </Link>
        ))}
      </div>
      <MobileProfileMenu />
    </>
  );
};

export const MobileProfileMenu = (): Node => {
  const router = useRouter();
  const onLogoutSuccess = () => {
    router.push('/login');
  };

  return (
    <div sxt="pt-4 pb-3 border-t border-gray-700">
      <div sxt="flex items-center px-5">
        <div sxt="flex-shrink-0">
          <span sxt="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
            <svg sxt="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        </div>
        <div sxt="ml-3">
          {/* <div sxt="text-base font-medium leading-none text-white">Tom Cook</div>
      <div sxt="text-sm font-medium leading-none text-gray-400">tom@example.com</div> */}
        </div>
      </div>
      <div sxt="mt-3 px-2">
        <Logout onSuccess={onLogoutSuccess}>
          {({ handleLogout, isLoading }) => (
            <a
              href="/logout"
              sxt={`block px-3 py-2 rounded-md text-base font-medium ${
                isLoading
                  ? 'text-gray-600 hover:text-gray-500 hover:bg-gray-700'
                  : 'text-gray-400 hover:text-white hover:bg-gray-700'
              }`}
              onClick={handleLogout}
            >
              <fbt desc="Item in Profile popup menu">Log out</fbt>
            </a>
          )}
        </Logout>
      </div>
    </div>
  );
};

type MobileMenuButtonProps = {
  +isOpen: boolean,
  +onClick: () => void,
};
export const MobileMenuButton = ({ isOpen, onClick }: MobileMenuButtonProps): Node => {
  const { id: userId } = useContext(CurrentUserContext);

  if (userId == null) {
    return null;
  }
  const closeIcon = <XIcon sxt="block h-6 w-6" aria-hidden="true" />;
  const menuIcon = <MenuIcon sxt="block h-6 w-6" aria-hidden="true" />;

  return (
    <button
      type="button"
      sxt="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
      onClick={onClick}
    >
      {isOpen ? closeIcon : menuIcon}
    </button>
  );
};
