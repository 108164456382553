// @flow

import type { Node } from 'react';
import { graphql, useMutation } from '@adeira/relay';
import { fbt } from 'fbt';

import { useAppMessage } from '../../useAppMessage';
import type { LogoutMutation } from './__generated__/LogoutMutation.graphql';

type Props = {
  +onSuccess: () => void,
  +children: ({
    +isLoading: boolean,
    +handleLogout: (event: SyntheticEvent<HTMLAnchorElement>) => void,
  }) => Node,
};

export default function Logout({ children, onSuccess }: Props): Node {
  const { showError } = useAppMessage();
  const [logout, isLoading] = useMutation<LogoutMutation>(graphql`
    mutation LogoutMutation {
      logout {
        __typename
        ... on User {
          id
        }
        ... on InputError {
          message
        }
        ... on SystemError {
          message
        }
      }
    }
  `);

  const handleLogout = (event) => {
    event.preventDefault();
    if (isLoading) {
      return;
    }

    logout({
      onError: () => {
        showError(fbt('Internal client error', 'Unexpected GraphQL error'));
      },
      onCompleted: ({ logout }, errors) => {
        if (errors != null) {
          showError(errors[0].message);
        }

        if (logout?.__typename === 'User') {
          onSuccess();
        } else {
          showError((logout: any)?.message ?? 'Server error');
        }
      },
    });
  };

  return children({ isLoading, handleLogout });
}
