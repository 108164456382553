// @flow strict-local

import { atom, type RecoilState } from 'recoil';

type State = {
  +message: FbtWithoutString | string | null,
  +type: 'success' | 'error' | null,
};

export const appMessageAtom = (atom({
  key: 'appMessageAtom',
  default: {
    message: null,
    type: null,
  },
}): RecoilState<State>);
