// @flow strict-local

import { type Node } from 'react';
import { useRecoilState } from 'recoil';

import { appMessageAtom } from '../recoil/appMessageAtom';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import { useAppMessage } from '../useAppMessage';

export default function AppMessage(): Node {
  const [appMessage] = useRecoilState(appMessageAtom);
  const { message, type } = appMessage;
  const { clear } = useAppMessage();

  if (message == null || message === '') {
    return null;
  }

  // Design from https://tailwindui.com/components/application-ui/feedback/alerts
  if (type === 'success') {
    return <SuccessMessage message={message} onDismiss={clear} testid="app-success-message" />;
  }

  return <ErrorMessage message={message} onDismiss={clear} testid="app-error-message" />;
}
